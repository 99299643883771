// About Us
export const IrinaVaschchuk = require('./AboutUs/IrinaVaschchuk.jpeg');
export const photographTeam = require('./AboutUs/photographTeam.png');
export const portrait1 = require('./AboutUs/portrait1.png');
export const portrait2 = require('./AboutUs/Rachel.jpg');
export const portrait3 = require('./AboutUs/portrait3.png');
export const portrait4 = require('./AboutUs/portrait4.png');
export const RubenCortez = require('./AboutUs/RubenCortez.jpg');
export const shipmentsLogistics = require('./AboutUs/woman-waits-for-her-order-delivery-man-in-uniform-2021-12-27-22-09-49-utc.jpg');
export const WesleyOBrien = require('./AboutUs/WesleyOBrien.jpg');
export const woman = require('./AboutUs/woman.png');
// Become A Seller
export const afiaThumbnail = require('./BecomeASeller/afia-thumbnail.png');
export const afia = require('./BecomeASeller/afia.png');
export const BCS1 = require('./BecomeASeller/BCS1.png');
export const BCS2 = require('./BecomeASeller/BCS2.png');
export const BCS3 = require('./BecomeASeller/BCS3.png');
export const BCS4 = require('./BecomeASeller/BCS4.png');
export const BCS5 = require('./BecomeASeller/BCS5.png');
export const BCS6 = require('./BecomeASeller/BCS6.png');
export const BCSItem1 = require('./BecomeASeller/aerial-view-of-tractor-mowing-green-field-in-finla-2022-02-08-22-39-29-utc.jpg');
export const BCSItem2 = require('./BecomeASeller/various-refreshing-drinks-in-glasses-with-ice-2023-02-20-02-20-45-utc.jpg');
export const BCSItem3 = require('./BecomeASeller/fresh-seafood-platter-with-caviar-close-up-2022-04-12-20-57-12-utc.jpg');
export const BCSItem4 = require('./BecomeASeller/aerial-view-of-tractor-mowing-green-field-in-finla-2022-02-08-22-39-29-utc.jpg');
export const BCSItem5 = require('./BecomeASeller/various-refreshing-drinks-in-glasses-with-ice-2023-02-20-02-20-45-utc.jpg');
export const BCSItem6 = require('./BecomeASeller/fresh-seafood-platter-with-caviar-close-up-2022-04-12-20-57-12-utc.jpg');
export const BecomeASellerBanner1 = require('./BecomeASeller/people-working-for-a-delivery-company-couriers-lo-2023-09-04-22-48-40-utc.jpeg');
export const BecomeASellerBanner2 = require('./BecomeASeller/with-vehicle-delivery-man-in-uniform-is-indoors-w-2021-12-27-22-04-04-utc.jpg');
export const LandingBanner1 = require('./BecomeASeller/LandingBanner1.png');
export const LandingBanner2 = require('./BecomeASeller/LandingBanner2.png');
export const muellerThumbnail = require('./BecomeASeller/mueller-thumbnail.png');
export const mueller = require('./BecomeASeller/mueller.png');
export const santacarotaThumbnail = require('./BecomeASeller/santacarota-thumbnail.jpg');
export const santacarota = require('./BecomeASeller/santacarota.jpg');
export const snowbbqThumbnail = require('./BecomeASeller/snowbbq-thumbnail.jpg');
export const snowsbbq = require('./BecomeASeller/snowsbbq.png');
export const WCSBreads = require('./BecomeASeller/WCSBreads.png');
export const WCSFullyCookedMeals = require('./BecomeASeller/WCSFullyCookedMeals.png');
export const WCSGlutenFree = require('./BecomeASeller/selection-of-gluten-free-products-on-wooden-backgr-2022-12-16-07-51-09-utc.jpg');
export const WCSMeatsSeafood = require('./BecomeASeller/WCSMeats&Seafood.png');
export const WCSVeganVegetarian = require('./BecomeASeller/WCSVegan&Vegetarian.png');
export const WCSVegetables = require('./BecomeASeller/WCSVegetables.png');
// Category Page
export const bannerCategoryMeatSeaFood = require('./CategoryPage/bannerCategoryMeatSeaFood.png');
export const steak1 = require('./CategoryPage/steak-1.png');
export const steak2 = require('./CategoryPage/steak-2.png');
export const steak3 = require('./CategoryPage/steak-3.png');
export const steak5 = require('./CategoryPage/steak-5.png');
// Contact Us
export const BannerContactUs = require('./ContactUs/BannerContactUs.png');
// FAQ
export const FAQsBanner = require('./FAQ/AdobeStock_554709992.jpeg');
// How It Works
export const HIWBox = require('./HowItWorks/HIWBox.png');
export const HIWPeople = require('./HowItWorks/HIWPeople.png');
export const HowItWorksBanner = require('./HowItWorks/HowItWorksBanner.png');
export const HowItWorksStep1 = require('./HowItWorks/HowItWorksStep1.svg');
export const HowItWorksStep2 = require('./HowItWorks/HowItWorksStep2.svg');
export const HowItWorksStep3 = require('./HowItWorks/HowItWorksStep3.svg');
export const HowItWorksStep4 = require('./HowItWorks/HowItWorksStep4.svg');
export const HowItWorksStep5 = require('./HowItWorks/HowItWorksStep5.svg');
// Landing Page
export const bestof1 = require('./LandingPage/bestof1.png');
export const bestof2 = require('./LandingPage/bestof2.png');
export const Categories5 = require('./LandingPage/Categories5.png');
export const freshBeverages = require('./LandingPage/freshBeverages.png');
export const Landing2 = require('./LandingPage/Landing-2.png');
export const MainBannerLandingPage = require('./LandingPage/MainBannerLandingPage.png');
export const organicCorn = require('./LandingPage/organicCorn.png');
export const ReadyMadeMealsBanner = require('./LandingPage/ReadyMadeMealsBanner.png');
export const steakAkaushiBeef = require('./LandingPage/steak-194927483-akaushiBeef.png');
export const steakAkaushiBeef2 = require('./LandingPage/steak-194927483-akaushiBeef2.png');
export const steakAkaushiBeef3 = require('./LandingPage/steak-194927483-akaushiBeef3.png');
export const vegetablesForBarbecue = require('./LandingPage/vegetablesForBarbecue.png');
export const bannerLanding1 = require('./LandingPage/topbanner-1254924430.jpg');
export const bannerLanding2 = require('./LandingPage/topbanner-673939528.jpg');
export const bannerLanding3 = require('./LandingPage/topbanner-1438332315.jpg');
// Product Detail
export const PDsteak1 = require('./ProductDetail/steak-1.png');
export const PDsteak2 = require('./ProductDetail/steak-2.png');
export const PDsteak3 = require('./ProductDetail/steak-3.png');
export const PDsteak4 = require('./ProductDetail/steak-4.png');
export const PDsteak5 = require('./ProductDetail/steak-188417741-freshfoodBanner.png');
export const Vendor = require('./ProductDetail/Vendor.png');
// Seller
export const Animal = require('./Seller/Animal.png');
export const bannerSeller = require('./Seller/bannerSeller.png');
export const seller = require('./Seller/seller.png');
export const sellerTracktor = require('./Seller/sellerTracktor.png');
export const Ssteak1 = require('./Seller/steak-1.png');
export const Ssteak2 = require('./Seller/steak-2.png');
export const Ssteak3 = require('./Seller/steak-3.png');
export const Ssteak4 = require('./Seller/steak-4.png');
export const Ssteak5 = require('./Seller/steak-5.png');
// Shopping Cart
export const glassGlobe = require('./ShoppingCart/AdobeStock_601290796.jpeg');
export const LakeInTheworldBanner = require('./ShoppingCart/sustainability-1428749606.jpg');
// Sustainability
export const BusinessColleaguesHavingDiscussion = require('./Sustainability/AdobeStock_266322925.jpeg')
export const GirlDirtyHandsOutdoors = require('./Sustainability/AdobeStock_614960447.jpeg')
export const GreenEarthOnHands = require('./Sustainability/AdobeStock_610354836.jpeg')
export const SustainabilityBanner = require('./Sustainability/AdobeStock_269481754.jpeg');
export const placeholder = require('./Sustainability/bottles-of-red-wine-2022-12-16-11-49-10-utc.jpg')
// Help Center
export const HelpCenterBanner = require('./HelpCenter/portrait-woman-customer-service-worker.png');
export const HelpCenterBannerBottom = require('./HelpCenter/LadyWorking.png');
// Best Seller
export const BottomBanner = require('./BestSellers/BottomBanner.jpg');
// Daily Deals
export const CyberCart = require('./DailyDeals/Cyber-cart.jpg');
export const TopMeals = require('./DailyDeals/Top-meals.jpg');
export const Orderings = require('./DailyDeals/Orderings.png');